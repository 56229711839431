#external-events {
  padding: 0 10px;
  border: 1px solid #ccc;
  background: #eee;
  overflow-y: auto;
  height: 100% !important;
}

.scheduling .fc-timeGridWeek-view .fc-scroller {
  height: calc(100vh - 322px) !important;
}

.scheduling .fc-dayGridMonth-view .fc-scroller {
  height: calc(100vh - 270px) !important;
}

.scheduling .fc-timeGridDay-view .fc-scroller {
  height: calc(100vh - 322px) !important;
}

.scheduling #external-events {
  padding: 0 10px;
  border: 1px solid #202329;
  background: #202329;
  width: 300px !important;
}

.scheduling .sub_title {
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 10px;
}

.scheduling .fc-event {
  display: flex;
  margin-bottom: 5px;
  padding: 8px 10px !important;
  background-color: #2e3239;
  color: #c7c7c7;
  border: none;
  border-radius: 0;
}

.scheduling .fc-event .img {
  width: 50px;
  margin-right: 10px;
}

.scheduling .fc-event .img img {
  width: 100%;
}

.scheduling .fc-event .channel_title {
  color: #f2f2f2;
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.scheduling #external-events::-webkit-scrollbar {
  width: 5px;
}

.scheduling #external-events::-webkit-scrollbar-track {
  background: #15171b;
}

.scheduling #external-events::-webkit-scrollbar-thumb {
  background: #424650;
}

.scheduling #external-events::-webkit-scrollbar-thumb:hover {
  background: #424650;
}

.scheduling .fc-day-grid-container::-webkit-scrollbar {
  width: 5px;
}

.scheduling .fc-day-grid-container::-webkit-scrollbar-track {
  background: #15171b;
}

.scheduling .fc-day-grid-container::-webkit-scrollbar-thumb {
  background: #424650;
}

.scheduling .fc-day-grid-container::-webkit-scrollbar-thumb:hover {
  background: #424650;
}

.scheduling .fc-timeGridWeek-view .fc-scroller::-webkit-scrollbar {
  width: 5px;
}

.scheduling .fc-timeGridWeek-view .fc-scroller::-webkit-scrollbar-track {
  background: #15171b;
}

.scheduling .fc-timeGridWeek-view .fc-scroller::-webkit-scrollbar-thumb {
  background: #424650;
}

.scheduling .fc-timeGridWeek-view .fc-scroller::-webkit-scrollbar-thumb:hover {
  background: #424650;
}

.scheduling .fc-timeGridDay-view .fc-scroller::-webkit-scrollbar {
  width: 5px;
}

.scheduling .fc-timeGridDay-view .fc-scroller::-webkit-scrollbar-track {
  background: #15171b;
}

.scheduling .fc-timeGridDay-view .fc-scroller::-webkit-scrollbar-thumb {
  background: #424650;
}

.scheduling .fc-timeGridDay-view .fc-scroller::-webkit-scrollbar-thumb:hover {
  background: #424650;
}

.scheduling .fc-unthemed td {
  border: #000 solid 1px;
}

.scheduling .fc_col {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scheduling .demo-app-calendar {
  width: calc(100% - 304px);
  padding: 10px;
  background-color: #202329;
}

.scheduling .fc-button {
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  font-weight: bold;
  height: inherit;
  color: #fff !important;
  background-color: #1a252f00 !important;
  border-color: #364759 !important;
  text-transform: uppercase;
}

.scheduling .fc-button.fc-button-active {
  background-color: #293541 !important;
}

.scheduling .fc-right .fc-button-group .fc-button {
  display: flex;
  align-items: center;
}

.scheduling .fc-right .fc-button-group .fc-buttonn .fc-icon {
  font-size: 18px;
}

.scheduling .fc-button:hover {
  background-color: #21252b !important;
}

.scheduling .fc-toolbar h2 {
  font-size: 15px;
  text-transform: uppercase;
}

.scheduling .fc th {
  font-size: 12px;
  border-color: #000;
  padding: 8px 4px;
}

.scheduling .fc-widget-header {
  padding: 0px;
}

.scheduling .fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px;
}

.scheduling .fc-unthemed td.fc-today {
  background: #101216;
}

.scheduling .fc-unthemed .fc-row {
  border-color: #000 !important;
}

.scheduling .fc-time-grid table {
  font-size: 13px;
  text-transform: uppercase;
}